import {Modal} from "react-bootstrap";
import {ItemOrderReceive, ItemOrderReceiveProps} from "../common/stock-receive/ItemOrderReceive";
import React from "react";
import itemOrderSelector from "../common/stock-receive/ItemOrderSelector";


export const StockReceiveWidget = (props: ItemOrderReceiveProps) => {
    return (
        <Modal
            backdrop='static'
            onExited={() => {
                props.onClose();
            }}
            show={!!props.itemOrder}
        >

            {props.itemOrder ? <ItemOrderReceive {...props}/> : null}
        </Modal>
    );
}
