import React from 'react';
import ReactDOM from 'react-dom/client';
import store from "../app/store";
import {Provider} from "react-redux";
import {OrderWetstock, TankProps} from "./OrderWetstock";

export default () => {
    const container = document.getElementById('order-wetstock');

    let root;

    let tankProps: TankProps[] = [];

    if (container) {


        root = ReactDOM.createRoot(
            container
        )


        const siteId = container.getAttribute('data-site-id');

        if (siteId != null) {

            root.render(
                <React.StrictMode>
                    <Provider store={store}>
                        <OrderWetstock siteId={+siteId}/>
                    </Provider>
                </React.StrictMode>
            )
        }


    }
};
