import axios from "../utils/axios";
import {stringify} from "qs";

export interface Department {
    departmentid: number;
    description: string;
}

export interface Barcode {
    barcodeid: number;
    barcode: string;
}

export interface StockLocation {
    stocklocationid: number;
    description: string;
}

export interface Item {
    stocklocations: StockLocation[]
    itemid: number;
    stockonhand: number;
    description: string;
    unitprice: number;
    sku: string;
    barcodes: Barcode[];
    marginData: {
        target: number;
        text: string;
    }
    pluOuterChild: {

    }
}

export interface LocationDatum {
    id: number;
    description: string;
    quantity: number;
}
export interface LocationData {
    complete: boolean;
    data: LocationDatum[];
}


export async function getDepartmentList(siteId: number) {
    let url = `/itemdrystock/${siteId}/departments`;

    const {data} = await axios.get<Department[]>(url);
    return data
}

export async function getSearchItem(siteId: number, search?: any, page = 0, expand = ['stocklocations', 'barcodes']) {
    let url = `/itemdrystock/${siteId}`;

    const params = stringify(search)


    if (search) {
        url += `/search?${params}`
    }

    url += `&expand=${expand.join(',')}`;

    if (page > 0) {
        url += `&page=${page}`;
    }

    return await axios.get<Item[]>(url);
}


export async function getLocationData(itemId: number ) {
    let url = `/itemdrystock/location-pre-adjustment-data/${itemId}`;

    return await axios.get<LocationData>(url);
}

export async function makeAdjustmentLocation(itemId: number, locationId: number, data: any, ) {
    let url = `/itemdrystock/make-location-pre-adjustment/?itemId=${itemId}&stockLocationId=${locationId}`;

    await axios.post(url, data);
}

export async function makeAdjustment(itemId: number, data: any, ) {
    let url = `/itemdrystock/adjustment/${itemId}`;

    await axios.post(url, data);
}
export async function finaliseLocationStocktake(itemId: number ) {
    let url = `/itemdrystock/finalise-location-adjustment/${itemId}`;

    await axios.post(url);
}
// url: `/itemdrystock/${siteId}/search?q=${encodeURIComponent(search)}`,
