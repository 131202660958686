import {Provider} from "react-redux"
import React, {Fragment, useEffect, useState} from "react";
import store, {RootState} from "../../app/store";
import {fetchDepartments, saveStocktake, searchItems, searchNextPage} from "./stocktakeSlice";
import {Field, Form, useFormState} from "react-final-form";
import {AutoSave, FieldPrefix, PrefixedField} from "../../common/FinalFormUtils";
import {Department, Item} from "../../api/drystockAPI";
import {Modal} from "react-bootstrap";
import {Link, useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {LocationBasedStocktakeForm} from "./LocationBasedStocktakeForm";
import {StocktakeForm} from "./StocktakeForm";
import {Simulate} from "react-dom/test-utils";


interface StocktakeProps {
    siteId: number
}

interface SDFProps {
    departments: Department[];

}

interface FilterItem {
    name: string;
    id: number;
}

interface BaseFiltererProps {
    prefix: string;
    items: FilterItem[]
}

interface FILProps extends BaseFiltererProps {
    disableOnLoading?: boolean,
    title: string
}


interface IProps {
    siteId: number;
    items: Item[];
    submitting: boolean;
}

interface STIMProps {
    show: boolean;
    onHide: () => void;
    selectedItem?: Item;
    siteId: number;
}

const StockTakeItemModal = ({onHide, selectedItem, show, siteId}: STIMProps) => {
    const dispatch = useAppDispatch<any>();


    if (!selectedItem) {
        return null;
    }

    let content = null;
    if (selectedItem?.stocklocations.length) {
        content = <LocationBasedStocktakeForm
            locations={selectedItem.stocklocations}
            onCancel={onHide} onFinalise={() => {
        }} itemId={selectedItem.itemid}/>
    } else {
        content = <StocktakeForm
            onSubmit={async (formValues: object) => {
                onHide();
                // history.push(`?${qs.stringify(formValues)}`)
                dispatch(saveStocktake(itemId, siteId, formValues));
            }}
            onCancel={onHide}
            // formData={props.selectedItem.value}
        />;
    }

    const itemId = selectedItem.itemid;

    return (
        <Modal show={show} onHide={onHide}>
            {/** @ts-ignore **/}
            <Modal.Header closeButton>
                <Modal.Title>
                    {selectedItem.description}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {content}
            </Modal.Body>
        </Modal>
    )
}

const StockTakeItems = ({items, submitting, siteId}: IProps) => {
    const [showItem, setShowItem] = useState<number>();

    let selectedItem = items.find((item) => item.itemid === showItem);

    return (
        <Fragment>
            <div className="card">
                <div className="list-group list-group-flush">
                    {
                        items.map((item) => {
                            return (
                                <button
                                    type="button"
                                    className="list-group-item list-group-item-action"
                                    onClick={() => setShowItem(item.itemid)}
                                    disabled={submitting}
                                >
                                    <h5 className="mb-1">{item.description}</h5>
                                    {/*<p className="mb-1">{item.}</p>*/}

                                    <dl>
                                        {
                                            item.barcodes.length > 0 ?
                                                <Fragment>
                                                    <dt>Barcodes</dt>
                                                    <dd>{item.barcodes.map(({barcode}) => barcode).join(", ")}</dd>
                                                </Fragment> : null
                                        }
                                        <dt>Unit Price</dt>
                                        <dd>{item.unitprice}</dd>
                                    </dl>
                                </button>
                            )
                        })
                    }
                </div>
            </div>

            <StockTakeItemModal
                show={!!showItem}
                onHide={() => setShowItem(undefined)}
                selectedItem={selectedItem}
                siteId={siteId}
            />
        </Fragment>

    )
}

const FilterItemList = ({items, prefix, disableOnLoading, title}: FILProps) => {
    const formState = useFormState();

    // TODO

    const [search, setSearch] = useState<string>();

    return (
        <div className="card">
            <div className="card-body">
                <h5>{title}</h5>
                <div>
                    <input onChange={(e) => setSearch(e.target.value)} className="form-control"/>
                </div>

                <FieldPrefix prefix={prefix}>

                    {items
                        .filter(({name}) => {
                            if (search) {
                                return name.indexOf(search) !== -1;
                            }

                            return true;
                        })
                        .map(({id, name}) => {
                            let inputName = '' + id;
                            return (
                                <div className="custom-control custom-checkbox">
                                    <PrefixedField
                                        // @ts-ignore
                                        disabled={disableOnLoading && formState.submitting}
                                        className={"custom-control-input"}
                                        name={inputName}
                                        component="input"
                                        type="checkbox"
                                        id={inputName}
                                    />

                                    <label className="custom-control-label"
                                           htmlFor={inputName}>{name}</label>
                                </div>
                            )
                        })}
                </FieldPrefix>
            </div>
        </div>
    )
}

const FilterTags = ({items, prefix}: BaseFiltererProps) => {
    return (
        <FieldPrefix prefix={prefix}>
            {
                items.map(({name, id}) => {
                    let inputName = `${id}`;

                    return (
                        <PrefixedField name={inputName}>
                            {
                                // @ts-ignore
                                (props: any) => (
                                    props.input.value ?
                                        <button className="btn btn-outline-dark mr-1 mt-1"
                                                onClick={() => props.input.onChange(undefined)}>x {name}</button> : null
                                )
                            }
                        </PrefixedField>
                    )
                })
            }
        </FieldPrefix>
    );
}

interface SIProps {
    name: string;
    delay: number;
}


const FilterSort = () => {
    return (
        <div>
            <Link to={"?sort=description"}>Description Up</Link>
            <Link to={"?sort=-description"}>Description Down</Link>
        </div>
    )
}

let timeout: undefined | NodeJS.Timeout;

const SearchInput = ({name, delay}: SIProps) => {
    const [newValue, setNewValue] = useState<string | undefined>();

    return (
        <Field name={name}>
            {props => {


                return (
                    <div className="form-group">
                        <label htmlFor="search-input">Search</label>
                        <input
                            id="search-input"
                            className="form-control"
                            type="text"
                            name={props.input.name}
                            value={props.input.value}
                            // onChange={props.input.onChange}
                            onChange={(e) => {
                                if (timeout) {
                                    clearTimeout(timeout)
                                }

                                const value = e.target.value;

                                timeout = setTimeout(() => {


                                    if (newValue !== value) {
                                        // values have changed and not currently submitting
                                        setNewValue(value);
                                        props.input.onChange(e);
                                    }
                                }, delay)
                            }}
                        />
                    </div>

                )
            }}
        </Field>
    )
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const StocktakeInner = ({siteId}: StocktakeProps) => {
    const {
        isDepartmentsLoading,
        departmentsError,

        isSearchLoading,
        departments,
        items,
        currentPage,
        pageCount
    } = useAppSelector((state: RootState) => state.stocktake);

    const dispatch = useAppDispatch<any>();


    useEffect(() => {
        // TODO selected section should probably be handled inside section slice
        dispatch(fetchDepartments(siteId));
    }, [dispatch, siteId]);

    useEffect(() => {
        // TODO selected section should probably be handled inside section slice
        dispatch(searchItems(siteId, initialValues));
    }, [dispatch, siteId]);


    const query = useQuery();

    const initialValues = {
        q: query.get('q'),
        departments: query.get('departments'),
        sort: query.get('sort')
    }

    let sort = query.get('sort');


    if (isDepartmentsLoading) {
        return null
    }

    if (departmentsError || (items == undefined)) {
        return <div>error</div>
    }

    const departmentsFilter: FilterItem[] = departments.map(({departmentid, description}) => (
        {id: departmentid, name: description}
    ));

    return (
        <div>
            <Form initialValues={initialValues} onSubmit={async (formValues) => {
                // history.push(`?${qs.stringify(formValues)}`)
                dispatch(searchItems(siteId, {...formValues, sort: sort}));
            }}>
                {({handleSubmit, values, submitting}) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <AutoSave

                                values={values}
                                submitting={false}
                                submit={handleSubmit}
                                delay={1000}
                            />

                            {/* search box */}
                            <div className="form-group">
                                {/*<label htmlFor="search-input">Search</label>*/}

                                <Field
                                    id="search-input"
                                    className="form-control"
                                    name="q"
                                    type="text"
                                    component="input"
                                    placeholder="Search..."
                                />
                            </div>

                            {/*<FilterSort/>*/}

                            <FilterTags items={departmentsFilter} prefix="departments"/>

                            <div className="d-flex mt-2">
                                <div className="filter-box-outer">
                                    <FilterItemList prefix="departments" items={departmentsFilter} title="Departments"/>
                                </div>
                                <div className="flex-grow-1 ml-2">
                                    <StockTakeItems items={items} submitting={submitting || isSearchLoading}
                                                    siteId={siteId}/>
                                    {
                                        currentPage < pageCount ?
                                            <div className="mt-1" style={{textAlign: "center"}}>
                                                <button
                                                    disabled={isSearchLoading}
                                                    className="btn btn-primary"
                                                    onClick={() => dispatch(searchNextPage(siteId))}>Load More
                                                </button>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Form>
        </div>
    )
}

export const Stocktake = ({siteId}: StocktakeProps) => {

    return (
        <Provider store={store}>
            <StocktakeInner siteId={siteId}/>
        </Provider>
    )
}