import React, {PureComponent, useEffect, useState} from "react";
import {Field, Form} from "react-final-form";
import {renderField} from "./StocktakeForm";
import {Provider, useDispatch, useSelector} from "react-redux"
import {finaliseLocationStocktake, makeAdjustmentLocation, StockLocation} from "../../api/drystockAPI";
import {RootState} from "../../app/store";
import {useAppSelector} from "../../app/hooks";
import {loadLocationData} from "./stocktakeSlice";

interface LocationBasedStocktakeFormProps {
    onCancel: () => void;
    onFinalise: () => void;
    locations: StockLocation[];
    itemId: number
}

export const LocationBasedStocktakeForm = ({
                                               itemId,
                                               onCancel,
                                               locations,
                                               onFinalise
                                           }: LocationBasedStocktakeFormProps) => {

    const [finalise, setFinalise] = useState(false)
    const [saving, setSaving] = useState(false)

    const {
        stocktakeLocationError,
        isStocktakeLocationLoading,
        stocktakeLocationData
    } = useAppSelector((state) => state.stocktake);

    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(loadLocationData(itemId));
    }, [dispatch, itemId, finalise]);

    if (isStocktakeLocationLoading) {
        return null;
        //     TODO show loader
    }

    if (!stocktakeLocationData || stocktakeLocationError) {
        // TODO display error ?
        onCancel();
        return null;
    }

    return (
        <div>
            <p>
                Stock quantities for locations.
            </p>
            <div>
                <p>Enter stocktake quantities for the following locations:</p>

                {locations.map(({stocklocationid, description}) => {
                    const dataForLocation = stocktakeLocationData.data.find((datum) => datum.id == stocklocationid);


                    return (
                        <Form

                            initialValues={{quantity: dataForLocation ? dataForLocation.quantity : 0}}

                            // mutators={{...arrayMutators}}
                            // initialValues={answers}


                            onSubmit={async (formData, form) => {
                                {
                                    setSaving(true);
                                    await makeAdjustmentLocation(itemId, stocklocationid, formData).then(() => {
                                        setSaving(false);
                                    }).catch(() => {
                                        form.reset();  // TODO error handling
                                    });
                                }
                            }}


                            subscription={{submitting: true, pristine: true, values: true}}

                        >

                            {({handleSubmit, pristine, submitting, submitFailed, values, active}) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Field
                                            label={description}
                                            type={"number"}
                                            component={renderField}
                                            className={"form-control"}
                                            name={`quantity`}
                                            required
                                        />


                                        <div className="clearfix">
                                            <div className="float-right">
                                                <button disabled={submitting || pristine || saving} type="submit"
                                                        className="btn btn-primary">Save
                                                </button>

                                            </div>
                                        </div>
                                    </form>
                                )
                            }}
                        </Form>
                    )
                })}
            </div>

            {/* TODO modal footer */}
            <div style={{marginTop: 15}}>
                <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel
                </button>

                <button disabled={saving} type="button" className="btn btn-primary float-right"
                        onClick={async () => {
                            setSaving(true);
                            // TODO separate state for finalisation / error handling
                            await finaliseLocationStocktake(itemId);
                            onCancel();
                        }}>Finalise
                </button>
            </div>
        </div>
    )
}
