import React from 'react';
import ReactDOM from 'react-dom/client';
import PaymentAllocation from "./PaymentAllocation";

export default () => {
    const container = document.getElementById('payment-allocation');

    if (container) {
        const siteId = container.getAttribute('data-site-id');


        const root = ReactDOM.createRoot(
            container
        )

        root.render(
            <PaymentAllocation siteId={siteId}/>,
        )
    }
};
