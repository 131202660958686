import React from 'react';
import {ItemOrder} from "../../orders/OrderForm";

interface ItemOrderSelectorProps {
    itemOrders: ItemOrder[];
    onSelect: (itemOrder: ItemOrder) => void;
}

export default  ({itemOrders, onSelect}: ItemOrderSelectorProps) => {
        return (
            <React.Fragment>
                {itemOrders.map((itemOrder) => {
                    return (
                        <p>
                            <strong>Supplier</strong><br/>
                            {itemOrder.organisationName}<br/>
                            <strong>Order Date</strong><br/>
                            {itemOrder.orderDate}<br/>
                            <strong>Quantity</strong><br/>
                            {itemOrder.unsuppliedQuantity}<br/>
                            <button className="btn btn-primary" onClick={() => onSelect(itemOrder)}>Receive</button>
                        </p>
                    )
                })}
            </React.Fragment>
        );
}
