import React from 'react';
import ReactDOM from 'react-dom/client';

import './scss/site.scss';

import App from './App';
import reportWebVitals from './reportWebVitals';
import renderOrders from "./orders/renderOrders";
import renderOrderWetstock from "./order-wetstock/renderOrderWetstock";
import renderReceiveStock from "./receive-stock/renderReceiveStock";
import renderStocktake from "./features/stocktake/renderStocktake";
import renderPaymentAllocation from "./payment-allocation/renderPaymentAllocation";

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

renderStocktake();
renderReceiveStock();
renderPaymentAllocation();
renderOrders();
renderOrderWetstock();


reportWebVitals(console.log);
