import React from 'react';

export default () => {
    // TODO search / adjustment error handling
    return (
        <div>
            Payment Allocation
        </div>
    );
}

