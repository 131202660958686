import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {DateTimeSelect} from "./DateTimeSelect";
import Moment from "moment";
import {Field, Form} from "react-final-form";

interface TankDipModalProps {
    show: boolean
    onClose: () => void
    // TODO tankreading type
    onSubmit: (tankReading: any) => void
    submitting: boolean
    storageTankId: number
    tankName: string
}

export const TankDipModal = ({submitting, show, onClose, onSubmit, storageTankId, tankName}: TankDipModalProps) => {
    const [dateTime, setDateTime] = useState<Moment.Moment | string>();


    let content: React.JSX.Element;
    if (dateTime) {
        let model = {
            storagetankid: storageTankId,
            localDateTime: dateTime && typeof dateTime !== 'string' ? dateTime.format("YYYY-MM-DD HH:mm:ss") : dateTime
        };

        content = <Form
            initialValues={model}
            onSubmit={onSubmit}
            // subscription={{submitting: true, pristine: true, values: true}}
        >
            {({handleSubmit}) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Field name="storagetankid" component="input" type="hidden"/>
                        <Field name="localDateTime" component="input" type="hidden"/>

                        <div className="form-group">
                            <label htmlFor="tank-dip-modal-volume">Fuel Volume (L)</label>

                            <Field id='tank-dip-modal-volume' className="form-control" component='input' type='number'
                                   name='volume'/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="tank-dip-modal-watercontent">Water Content (L)</label>

                            <Field id='tank-dip-modal-watercontent' className="form-control" component='input' type='number'
                                   name='watercontent'/>
                        </div>


                        <div className="clearfix">

                                <button type="button" className="btn btn-secondary" onClick={onClose}>Cancel</button>
                                <button type="submit" disabled={submitting} className="btn btn-primary float-right">Save
                                    Dip
                                </button>

                        </div>

                    </form>
                )
            }}
        </Form>

    } else {
        content = <DateTimeSelect onSubmit={(value) => setDateTime(value)}/>;
    }

    return (
        <Modal show={show} onExited={() => setDateTime(undefined)} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Tank Dip <small>{tankName}</small></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {content}
            </Modal.Body>
        </Modal>
    );
};



