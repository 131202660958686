import React, {useEffect} from "react";
import {Field, Form} from "react-final-form";
import DateTime from "react-datetime";
import Moment from "moment";
import createDecorator from "final-form-calculate";
import {ReceiveStockPriceAdjust} from "./ReceiveStockPriceAdjust";
import {useReceiveItemOrderMutation} from "../../services/drystock";
import {ItemOrder} from "../../orders/OrderForm";
import {Modal} from "react-bootstrap";
import {ItemOrderReceive} from "./ItemOrderReceive";

interface RSFProps {

    itemOrder: ItemOrder
    onClose: () => void
    closeOnFinish?: boolean
}
export const ReceiveStockForm = ({itemOrder, onClose, closeOnFinish}: RSFProps) => {
    const [
        receiveItemOrder,
        receiveItemOrderStatus
    ] = useReceiveItemOrderMutation({
        fixedCacheKey: `receive-stock-${itemOrder.itemorderid}`
    });


    useEffect(() => {
        if (receiveItemOrderStatus.fulfilledTimeStamp !== undefined && !itemOrder.shouldAdjustPrice) {
            receiveItemOrderStatus.reset();
            closeOnFinish && onClose();
        }
    }, [receiveItemOrderStatus, itemOrder, onClose, closeOnFinish]);


    const calculator = createDecorator({
        field: 'dateTime-field',
        updates: {
            // @ts-ignore
            dateTime: (ignoredValue, allValues: AllValues) => {
                const dateTime = allValues['dateTime-field'];

                if (Moment.isMoment(dateTime)) {
                    return dateTime.format("YYYY-MM-DD HH:mm:ss");
                }
            }
        }
    });

    if (receiveItemOrderStatus.fulfilledTimeStamp !== undefined && itemOrder.shouldAdjustPrice) {
        return (
            <ReceiveStockPriceAdjust
                closeOnFinish={closeOnFinish}
                onClose={() => {
                    receiveItemOrderStatus.reset();
                    onClose()
                }}
                itemId={itemOrder.itemid}
            />
        );
    }

    return (
        <Form
            initialValues={{
                quantity: itemOrder.unsuppliedQuantity,
                unitCost: itemOrder.unitcost,
                itemOrderId: itemOrder.itemorderid
            }}

            decorators={[calculator]} onSubmit={(values) => {
            receiveItemOrder(values)
        }}>
            {({handleSubmit, values, valid, dirty}) => {
                return (
                    <form onSubmit={handleSubmit}>

                        <div className="form-group">
                            <label htmlFor="quantity">Quantity</label>

                            <Field id='quantity' className="form-control" component='input' type='number'
                                   name='quantity'/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="unit-cost">Unit Cost</label>

                            <Field id='unit-cost' className="form-control" component='input' type='number'
                                   name='unitCost'/>

                        </div>

                        <div className="form-group">
                            <label>Date</label>

                            <Field
                                name={'dateTime-field'}

                                validate={(value) => {
                                    if (!Moment.isMoment(value)) {
                                        return 'Must be a valid date'
                                    }

                                    return undefined;
                                }}
                            >

                                {props => (
                                    <DateTime
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                    />
                                )}
                            </Field>
                        </div>

                        <div className="d-flex">
                            <button disabled={receiveItemOrderStatus.isLoading} type="button"
                                    className="btn btn-secondary" onClick={onClose}>Cancel
                            </button>
                            <button disabled={receiveItemOrderStatus.isLoading} type="submit"
                                    className="ml-auto btn btn-primary">Submit
                            </button>
                        </div>
                    </form>
                )
            }}
        </Form>
    )
}
