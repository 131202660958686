import {Field, Form} from "react-final-form";
import {ItemOrder, PurchaseOrder} from "../../orders/OrderForm";
import {
    useGetOrderQuery,
    usePostDropMutation,
    usePostReceiveAllMutation,
} from "../../services/drystock";
import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import DateTime from "react-datetime";
import {WetstockForm} from "../../order-wetstock/WetstockFormExistingOrder";


interface ReceiveAllFormProps {
    order: PurchaseOrder
    onClose: () => void
}

interface ReceiveAllDropFormProps {
    itemOrder: ItemOrder
    dateTime: string
    submitting: boolean
}

const ReceiveAllDropForm = ({dateTime, itemOrder, submitting}: ReceiveAllDropFormProps) => {
    const [postDrop, postDropStatus] = usePostDropMutation();

    return (
        <div>
            <h5>{itemOrder.itemName}</h5>
            <WetstockForm
                initialValues={{}}
                submitText={'Post Drop'} unitCost={itemOrder.unitcost} tanks={itemOrder.tanks}
                volume={itemOrder.unsuppliedQuantity}
                dateTime={dateTime}
                id={`wetstock-form-${itemOrder.itemorderid}`}
                onSubmit={(formData) => postDrop({
                    itemOrder,
                    dropData: formData
                })}
                submitting={postDropStatus.isLoading || submitting}
            />

        </div>
    )
}

export const ReceiveAllForm = ({order: {itemorders, supplierorderid}, onClose}: ReceiveAllFormProps) => {
    const [dateTime, setDateTime] = useState<string>();

    const [
        postReceiveALl,
        postReceiveAllStatus
    ] = usePostReceiveAllMutation();

    const {isLoading, isError} = useGetOrderQuery({
        id: supplierorderid
    });

    useEffect(() => {
        if (postReceiveAllStatus.fulfilledTimeStamp !== undefined) {
            onClose();
        }
    }, [onClose, postReceiveAllStatus.fulfilledTimeStamp]);

    const itemOrdersToSelectGrade = itemorders.filter(({tanks, unsuppliedQuantity}) => tanks.length > 1
        && unsuppliedQuantity > 0);

    // const initialValues = itemOrdersToSelectGrade.map(({itemorderid, tanks}) => (
    //     {id: itemorderid, tankId: tanks[0].storagetankid}
    // ));
    const isAnyLoading = isLoading || postReceiveAllStatus.isLoading;

    return <Form
                 onSubmit={() => {
                     dateTime && postReceiveALl(({
                                 supplierOrderId: supplierorderid,
                                 dateTime: dateTime
                             }
                     ))
                 }}>

        {({handleSubmit, values, valid, dirty}) => {
            return (
                <form onSubmit={handleSubmit}>


                    <Field name="dateTime">
                        {({input, meta}) => (
                            <div className="form-group">
                                <label>Date</label>

                                <DateTime
                                    onChange={(dateTime) => {
                                        if (!(typeof dateTime === 'string')) {
                                            setDateTime(dateTime.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                    }}
                                    input={false}
                                />
                            </div>
                        )}
                    </Field>

                    {dateTime ? itemOrdersToSelectGrade.map((itemOrder) => (
                        <ReceiveAllDropForm submitting={isAnyLoading} itemOrder={itemOrder} dateTime={dateTime}/>
                    )) : null}

                    <div className="d-flex mt-1">
                        <button disabled={isAnyLoading} type="button"
                                className="btn btn-secondary" onClick={onClose}>Cancel
                        </button>
                        <button disabled={isAnyLoading || itemOrdersToSelectGrade.length > 0}
                                type="submit"
                                className="ml-auto btn btn-primary">Submit
                        </button>
                    </div>
                </form>
            )
        }}
    </Form>
}


export const ReceiveAllFormModal = ({show, ...rest}: ReceiveAllFormProps & { show: boolean }) => (
    <Modal
        backdrop='static'
        onExited={() => {
            rest.onClose();
        }}
        show={show}
    >
        <Modal.Header closeButton>
            <Modal.Title>Receive All</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <ReceiveAllForm {...rest}/>

        </Modal.Body>
    </Modal>
);
